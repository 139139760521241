/* --- SETTINGS --- */

// Theme colors
// $primary: #3a70cf;
$primary: #3e85ff;
$secondary: #a3daff;
// $tirtiary: #ffffed;
$ribbonColor: #ebb134;
$secondaryBackground: #fefce2; // formerly #fff9db
$secondaryBackgroundSwitch: #d4d098; // formerly #fff9db
$appbar: #3e85ff;
// $appbar: #3a70cf;
$background: #e4ebf2;
$tirtiaryBackground: #fafafa;
$tirtiary: rgb(219, 236, 241);
$highlightEmphasis: #7ccbff;
$checkmarkGreen: rgb(34, 188, 102);
// $primary: rgb(62,133,255);
// $background: #dfe4e8;
// $appbar: #433f13;
// $appbar: rgb(62,133,255);
// $appbar: #5583d4;
// $appbar: #555555;
// $appbar: #223B3E;
// $appbar: rgba(0, 0, 0, .3);
// $appbar: #464646;
// $appbar: #849bb5;




// Standard colors
$white: #ffffff;
$offWhite: rgb(245, 245, 245);
$whiteSecondary: rgba(255, 255, 255, 0.692);
$grey: grey;
$darkGrey: #616161;
$black: black;
$green: rgb(0,128,0);

// Distances and durations
$toolbar-height: 48px;
$clicked-animation-duration: 3s;

:export {
  primary: $primary;
  secondary: $secondary;
  tirtiary: $tirtiary;
  ribbonColor: $ribbonColor;
  secondaryBackground: $secondaryBackground;
  appbar: $appbar;
  background: $background;
  tirtiaryBackground: $tirtiaryBackground;
  paperColor: $white;
  offWhite: $offWhite;
  whiteSecondary: $whiteSecondary;
  grey: $grey;
  darkGrey: $darkGrey;
  black: $black;
  green: $green;
  toolbarHeight: $toolbar-height;
  secondaryBackgroundSwitch: $secondaryBackgroundSwitch;
  highlightEmphasis: $highlightEmphasis;
  clickedAnimationDuration: $clicked-animation-duration
}

/* --- MIXINS --- */
@mixin card-width {
  @media only screen and (max-width: 459px) {
      width: 320px;
  }
  @media only screen and (min-width: 460px) {
      width: 400px;
  }
}


/* --- STYLES --- */


// Global
body {
  background: $background;
  margin: 0;
}

.admin__categories {
  padding-bottom: 16px;
  border-bottom: 2px solid $black;
}

.admin-category-row {
  display: flex;
  align-items: row;
  justify-content: space-between;
  margin: 8px;
}

.admin-create-category {
  display: flex;
  align-items: row;
  justify-content: space-between;
  background-color: $tirtiary;
}

.admin-create-suggestion {
  display: flex;
  align-items: row;
  justify-content: space-between;
  background-color: $tirtiary;
}

.admin-text-small {
  width: 25px;
}

.admin-text-medium {
  width: 200px;
}

.admin-text-large {
  width: 500px;
}

.admin__title {
  display: flex;
  align-items: row;
  justify-content: center;
}

.admin__suggestions {
  padding-bottom: 16px;
  border-bottom: 2px solid $black;
}

.admin-suggestion-row {
  display: flex;
  align-items: row;
  justify-content: space-between;
}

.avatar-menu__icon {
  margin-right: 8px; 
  margin-top: 8px; /*Change this to adjust the icon*/

}

.avatar-menu__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px;
}

.billing-content__status-box {
  padding: 15px;
  border: solid 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
}

.billing-content__status-box-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.choose-templates-card {
  // width: 400px;
  @include card-width;
}

.choose-templates-card__title {
  padding: 16px;
  background-color: $white;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.copy__text {
  margin-right: 16px;
}

.copy__button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
}

.corner-ribbon__product {
  position: relative;
}

.corner-ribbon__ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
  .corner-ribbon__ribbon {
    font: bold 11px sans-serif;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform:    rotate(45deg);
    -ms-transform:     rotate(45deg);
    -o-transform:      rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    top: 22px;
    right: 22px;
    width: 150px;
    background-color: $ribbonColor;
    color: $white;
  }
    .corner-ribbon__ribbon__coming-soon {
    font: bold 11px sans-serif;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform:    rotate(45deg);
    -ms-transform:     rotate(45deg);
    -o-transform:      rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    top: 22px;
    right: 22px;
    width: 150px;
    background-color: $darkGrey;
    color: $white;
  }
}

.country-logo {
  margin-right: 8px;
}

.delete-message-page__actions {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
}

.delete-message-page__subject-field-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  // padding-right: 8px;
}


.divider {
  width: 100%;
  border-top: 1px solid $darkGrey;
  margin-top: 8px;
  margin-bottom: 8px;
}

.editor {
  display: flex;
  flex-direction: column;
  align-items: stretch;  
  height: calc(100vh - #{$toolbar-height});
  min-height: calc(100vh - #{$toolbar-height});
  overflow: scroll;
}

.fallback {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 70px;
  color: $black;
}

.fallback__button {
  margin-left: 16px;
  margin-right: 16px;
}

.fallback__title {
  max-width: 400px;
  @media only screen and (max-width: 459px) {
    width: 320px;
  }
}

.fallback__subtitle {
  color: $black;
  max-width: 400px;
  @media only screen and (max-width: 459px) {
    width: 320px;
  }
}

.fallback__buttons {
  margin-top: 16px;
}

.footer__text {
  color: $darkGrey;
}

.footer__terms-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer__terms-element {
  margin-left: 8px;
  margin-right: 8px;
}

.get-started {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;  
}

.hide-when-xs {
  @media screen and (max-width: 599px) {
    // visibility: hidden;
    display: none;
  }
}

.show-when-xs {
  @media screen and (min-width: 599px) {
    // visibility: hidden;
    display: none;
  }
}

.highlight-button-switch__button {
  background-color: $white;
  color: $white;
}

// Hightlight Text Area

.hwt-container-0 {
	display: inline-block;
	position: relative;
	overflow: hidden !important;
	-webkit-text-size-adjust: none !important;
}

.hwt-container {
	display: inline-block;
	position: relative;
	overflow: hidden !important;
  -webkit-text-size-adjust: none !important;
  width: 100%; // I adjusted this so that the highlights would appear for the entire box rather than stop halfway.
}

.hwt-backdrop {
	position: absolute !important;
	top: 0 !important;
	right: -99px !important;
	bottom: 0 !important;
	left: 0 !important;
	padding-right: 99px !important;
	overflow-x: hidden !important;
  overflow-y: auto !important;
  // opacity: 0.4;
}

.hwt-backdrop__custom-overlay-add {
  z-index: 2;
  opacity: 0.6;
}

.hwt-highlights {
	width: auto !important;
	height: auto !important;
	border-color: transparent !important;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
	color: transparent !important;
	overflow: hidden !important;
}

.hwt-input {
	display: block !important;
	position: relative !important;
	margin: 0;
	padding: 0;
	border-radius: 0;
	font: inherit;
	overflow-x: hidden !important;
	overflow-y: auto !important;
}

.hwt-content {
	border: 1px solid;
	background: none transparent !important;
}

.hwt-content mark {
	padding: 0 !important;
	color: inherit;
}

.hwt__custom-blue {
  background-color: $secondary;
  cursor: pointer;
}

.hwt__custom-blue-clicked {
  background-color: $highlightEmphasis;
  animation: clickedSuggestionAnimation $clicked-animation-duration forwards;
}

@keyframes clickedSuggestionAnimation {
  from {
    background-color: $secondary;
  }
  25% {
    background-color: $highlightEmphasis;
  }
  to {
    background-color: transparent;
  }
}

.hwt__custom-brackets {
  background-color: $tirtiary;
}

.hwt__custom-button {
  border-bottom: 1px solid $darkGrey;
  border-top: 1px solid $darkGrey;
  border-left: 1px solid $darkGrey;
  border-right: 1px solid $darkGrey;
  
  // background-color: #a3daff;
  // cursor: pointer;
  // background-color: #fff9db;
  // border-radius: 5px;
  // border-style: dotted;
  // background-color: #fff;
  // border-bottom: 4px solid grey;
}

.hwt__disabled-mark {
  background-color: transparent;
  
}


.intro-card {
  // width: 400px;
  @include card-width;
  margin-top: 16px;
  margin-bottom: 16px;
}

.loading-page__container {
  width: 100vw;
  height: 100vh;
  padding: 30px;
  background: $white;
  position: relative;
}

.loading-page__spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.messages-page {
  margin: 16px;
}

.messages-table__end-head {
  margin-right: 48px;
}

.messages-table__start-head {
  margin-left: 48px;
}

.notepad {
  width: 100%;
  height: 100%;
}

.notepad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - #{$toolbar-height});
  overflow: scroll;
}

.notepad__paper {
  margin: 16px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 215px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.notepad__subject-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notepad__subject-bar-subject {
  flex: 1
}

.page-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pricing__contact-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
}

.pricing__contact-space {
  margin-left: 8px;
}

.pricing__list-checkmarks {
  list-style: none;
  padding-left: 0;  
}

.pricing__list-checkmarks li {
  position: relative;
  padding-left: 1.5em;  /* space to preserve indentation on wrap */
}

.pricing__list-checkmarks li:before {
  content: '✓';
  color: $checkmarkGreen;
  position: absolute;
  left: 0;  /* place the checkmark at the start of the padding */
  width: 1em;
  height: 1em;
}

.open-message__blank {
  margin-left: 24px;
  margin-right: 24px;
}

.retrieve-provided-data {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.set-roles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
}

.signup-page__sign-in {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.signup-page__terms-text {
  margin-left: 14px;
  margin-right: 14px;
}

.subject {
  padding: 8px;
  max-height: 48px;
}

.subject__line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subject__text-field-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  // padding-right: 8px;
}

.technique-box {
  margin: 16px;
}

.technique-list__show-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.template-card {
  opacity: 0.9;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.template-card__details {
  background-color: $offWhite;
}

.template-card:hover {
  opacity: 1;
  box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)
}

.template-card__link {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.template-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.template-thumbnail__icon {
  height: 48px;
  margin: 16px;
}

.templates {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 40px;
}

.templates-header-card__media {
  height: 280px;
  opacity: .8;
}

.templates__subtitle {
  color: $black;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}

.templates__title {
  color: $black;
  font-size: 48px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 8px;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $toolbar-height;
}

.toolbar__button {
  margin-left: 16px;
  color: $white;
}

.toolbar__end-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.toolbar__start-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.start-free-trial__subtext {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
}

.update-password__view-account {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}